@import '~@ebay/skin/tokens.css';
@import '~@ebay/skin/less.less';

.vas-spoke-wrap-d {
  font-size: @font-size-14;
  svg {
    display: none;
  }

  a,
  a:focus,
  a:visited {
    color: var(--color-blue-500);
    text-decoration: underline;
  }

  .vas-spoke-header {
    display: flex;
    flex-shrink: 0;
    position: relative;
    align-items: center;
    margin: @spacing-200 @spacing-200 0 @spacing-200;

    &-text {
      flex: 1 1 auto;
      font-size: @font-size-20;
      color: var(--color-foreground-primary);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin: 0;
    }

    &-close-btn {
      height: 32px;
      width: 32px;
      border: none;
      background: none;
      cursor: pointer;
      svg.icon.icon--close-24 {
        // add color to skin vars?
        fill: #111820;
        display: inline;
      }
    }
  }

  .vas-spoke-item-card {
    .vas-itm-card {
      padding: @spacing-200 @spacing-300 @spacing-300 @spacing-300;
      .vas-itm-card__title {
        color: var(--color-foreground-primary);
        font-weight: @font-weight-regular;
      }
    }
    .vas-itm-card__image {
      width: 48px;
      height: 48px;
    }
  }

  .vas-spoke-items {
    margin-bottom: 80px;
    min-height: 200px;
    padding: 0 @spacing-300;
    overflow-y: scroll;
    position: relative;
    border-top: 1px solid var(--color-background-tertiary);
    fieldset {
      border: 0;
      margin: 0;
      .vas-spoke-items-left {
        width: 100%;
        .vas-spoke-item-heading {
          color: var(--color-neutral-900);
          font-size: @font-size-16;
        }
      }
      .vas-spoke-image-right {
        // This is only place where float is used. It is needed to allow the left side content to accupy white space below the image.
        float: right;
        padding-left: 5px;
        .vas-itm-card {
          padding: 0;
        }
      }
    }
    &-item {
      padding: @spacing-300 0;
      border-top: 1px solid var(--color-background-tertiary);
      &-sec-labels {
        ul {
          padding-left: @spacing-250;
          color: var(--color-foreground-secondary);
        }
      }
      &-ter-label {
        margin-top: 5px;
      }
    }
    .field {
      display: flex;
      align-items: center;
      margin: @spacing-200 0;
      .field__label {
        margin-left: @spacing-200;
      }
    }
    & > div:first-child {
      border-top: none;
    }
  }

  .vas-spoke-items__multi {
    height: 60vh;
    max-height: 1000px;
    min-height: 400px;
  }

  .bottom-ctas {
    position: fixed;
    width: 100%;
    right: 0;
    bottom: 0;
    background: white;
    z-index: 99999;
    display: flex;
    flex-direction: row-reverse;
    border-radius: 0 0 10px 10px;
    box-shadow: 0 -4px 4px #0000000f;
    .vas-ctas {
      padding-right: 16px;
      button:focus {
        outline: 1px dotted var(--color-foreground-secondary);
        outline-offset: 1px;
      }
    }
    &-btn {
      margin-left: @spacing-200;
      &-spinner {
        height: @spacing-200;
        width: @spacing-200;
      }
    }
  }
}

@media (max-width: @_screen-size-MD) {
  .vas-spoke-wrap-d {
    .vas-spoke-header {
      display: flex;
      .vas-spoke-header-text {
        margin-left: @spacing-100;
      }
    }
    .vas-spoke-items, .vas-spoke-items__multi {
      border-bottom: none;
      overflow-y: scroll;
      // 260 includes the Height of Header + CTAS section + Some Padding
      height: ~"calc(100vh - 260px)";
      .vas-spoke-image-right {
        .vas-itm-card {
          .vas-itm-card__image {
            width: 48px;
            height: 48px;
          }
        }
      }
    }
    .bottom-ctas {
      position: sticky;
      bottom: env(safe-area-inset-bottom);
      display: block;
      margin: @spacing-200;
      padding-bottom: @spacing-200;
      width: auto;
      border: none;
      border-radius: none;
      box-shadow: none;
      .vas-ctas {
        padding-right: 0;
      }
    }
  }
}