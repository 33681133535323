@import './common.less';
.parts-introduction {
  .introduction {
    &__content {
      height: ~"calc(889px - 176px)";
    }

    &__top-content {
      border-radius: 16px 16px 0 0;
      img {
        height: 284px;
      }
    }

    &__bottom-content {
      padding: 24px 24px 16px;
      padding-bottom: 90px;

      .card-tile__label > h2 {
        .typography-giant-3();
      }
      .card-tile__card-right {
        margin-right: 24px;
      }
    }

    &__footer {
      display: flex;
      justify-content: space-between;
      box-shadow: 0 -4px 4px #0000000f;
      border-radius: 0 0 10px 10px;
      &__sec-labels {
        padding: 20px;
        .ux-textspans--PSEUDOLINK {
          color: var(--color-foreground-primary);
        }
      }
    }
  }
  .screen-loading {
    padding: 60px 32px 32px 32px;
    height: 750px;
    &__content-right {
      .skeleton__image {
        height: 600px;
      }
    }
  }
}