@import '../../common/loading.less';

.loader {
  width: 100%;

  &__list {
    width: 100%;
    background-color: #e5e5e5;
    border: 1px solid transparent;
    border-radius: 4px;
    height: 90px;
    margin-bottom: 20px;

    &:last-child {
      margin: 0;
    }

    .animateMixins();
  }
}
