@import '../../../common/loading.less';

.screen-loading {
  padding: 32px 0;

  &__content {
    &-top {
      width: 100%;
    }

    &-main {
      display: flex;
      justify-content: space-between;
      padding-top: 32px;
    }

    &-left {
      width: 50%;
      height: 100%;
      justify-content: flex-start;
      .skeleton {
        padding-bottom: 16px;
      }
    }

    &-right {
      width: 45%;
      height: 100%;
      justify-content: flex-end;
    }

    &-footer {
      margin-top: 10px;
    }
  }
}