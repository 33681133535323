@import '~@ebay/skin/tokens.css';
@import '~@ebay/skin/less.less';

.parts-installer {
  padding: 16px 32px 32px;

  &__lists,
  &__form-fields {
    display: flex;
    justify-content: space-between;
  }

  &__form-fields {
    align-items: baseline;
  }

  &__form-fields {
    .radius select,
    .postal-code input {
      width: 135px;
    }
  }

  &__card-lists
  .installer-list,
  &__map-lists {
    height: 690px;

    .toggle-button-group {
      container: auto;
    }

  }

  &__card-lists,
  &__map-lists,
  &__finder,
  &__sort {
    box-sizing: border-box;
  }

  &__card-lists {
    width: 370px;

    .inline-notice {
      margin: 0 16px 0 0;
      .inline-notice__title {
        .typography-regular();
        margin: 0;
      }
    }
    .installer-card-no-radio__name {
      .typography-regular();
    }
    .installer-card-no-radio__address {
      span:first-child {
        .typography-regular();
        font-weight: 400;
      }
    }
  }

  &__map-lists {
    width: 470px;
  }

  &__sort {
    align-content: flex-end;

    select {
      margin-left: 16px;
    }
  }

  &__lists {
    padding-top: 16px;
  }

  .loader__list {
    width: 96%;
  }
  .screen-loading {
    padding: 32px 0 0;
    height: 750px;
    &__content-main {
      padding: 0;
    }
    &__content-right {
      .skeleton__image {
        height: 650px;
      }
    }
  }
  &-loading {
    .screen-loading {
      padding: 0 32px 32px;
      height: 750px;
      &__content-main {
        padding: 0;
      }
      &__content-left {
        .skeleton {
          padding-bottom: 16px;
        }
        .skeleton__image {
          height: 225px;
        }
      }
      &__content-right {
        .skeleton {
          padding-bottom: 16px;
        }
        .skeleton__image {
          height: 225px;
        }
      }
    }
  }
}