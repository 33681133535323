@import '~@ebay/skin/tokens.css';
@import '~@ebay/skin/less.less';

.vas-ctas {
  button,
  a {
    margin: @spacing-200 0;
  }

  &__ok {
    text-align: center;
    background-color: var(--color-background-primary);
    color: var(--color-foreground-secondary);
    border-bottom: 1px solid var(--color-background-disabled);
    height: 0px;

    span {
      position: relative;
      top: -8px;
      background-color: var(--color-background-primary);
      padding: 5px;
    }
  }
}
