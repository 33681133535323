@import '~@ebay/skin/less.less';
@import '~@ebay/skin/tokens.css';

.parts-scheduling {

  &__image {
    width: 100%;
    border-radius: 5px;
  }

  &__service-operation {
    margin-bottom: 16px;

    p {
      padding-left: 8px;
      margin: 0;
      .typography-small();
    }

    svg {
      color: var(--color-foreground-secondary);
    }
  }

  &__clock,
  &__provider-text {
    span {
      display: flex;
      justify-content: space-between;
      padding: 1px;
    }
  }

  &__phone,
  &__place {
    span {
      color: var(--color-foreground-primary);
    }
  }

  &__help {
    padding-bottom: 24px;
  }

  &__help h4 {
    .typography-regular-bold();
  }

  &__help-item  ul {
    padding-left: 32px;
    margin: 0;
  }

  &__help-item li {
    .typography-regular-secondary();
  }

  &__provider {
    margin-top: 24px;
    padding: 16px;
    background-color: var(--color-background-secondary);
    border-radius: 5px;

    &-image {
      object-fit: cover;
    }

    &-text {
      padding: 0 0 0 16px;

      span {
        display: inline-block;
        padding: 0 0 5px 8px;
        color: var(--color-foreground-primary);
      }

      svg {
        padding-left: 5px;
      }
    }
  }

  &_faq-title {
    margin: 24px 0;
  }

  &__faq-deatils {
    list-style: none;
    padding: 0;
  }

  // installer card overrides
  &__installer-card {
    padding-bottom: 16px;
    .installer-card-no-radio__name, .installer-card-no-radio__address, .installer-card-no-radio__star-rating, .installer-card-no-radio__accessory-label {
      padding: 0 0 4px 0;
    }
  }

  &__installer-card>.installer-card-no-radio {
    width: 100%;
    display: block;
  }

  .installer-card-no-radio__name {
    line-height: 24px;
  }

  .installer-card-no-radio__address {
    line-height: 20px;
  }

  .installer-card-no-radio__star-rating {
    align-items: normal;
    line-height: 16px;
    div.star-rating {
      width: 85px;
    }
  }

  .installer-card-no-radio__star-rating-average {
    margin-left: 0;
  }

  &__footer {
    height: 80px;
  }

  &__time-slot>.toggle-button-group>ul {

    display: inline-block;

    li {
      margin: 0;

      button:disabled,
      button[disabled] {
        display: none;
      }

      button {
        width: 92%;
        padding-top: 5px;
        padding-bottom: 5px;
        margin-bottom: 12px;
        height: auto;
      }
      width: auto;

    }
  }

  &__right-title {
    margin: 0 0 16px;
  }

  &__clock > span:first-child {
    padding-bottom: 8px;
  }

  &__clock > span > span:first-child {
    padding-right: 16px;
  }
}