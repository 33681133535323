.vas-interstitial-enrollment {
  margin: -1.5rem;

  &-wrapper {
    position: relative;
  }

  &-wrapper-div {
    position: absolute;
    z-index: 1;
    right: 0;
  }

  svg.icon--spinner-large {
    display: block;
  }

  &-close-btn {
    top: 1;
    right: 0;
    margin: 24px;
    border-radius: 50%;
    background-color: #ffffff;
    border: none;
    height: 40px;
    width: 40px;
    padding-left: 11px;

    svg.icon.icon--close-16 {
      display: block;
      fill: #111820;
    }
  }

  &-iframe {
    position: absolute;
    height: 100%;
    width: 100%;
    border-radius: 1rem;
  }

  &-iframe__identity {
    border-radius: 1rem;
    height: 568px;
    width: 392px;
    margin: auto;
    margin-top: 30px;
  }

  .full-page-overlay {
    z-index: 10000000;
    background-color: rgba(17, 24, 32, 0.7);
    opacity: 0.2;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 1rem;
  }

  .full-spinner {
    display: flex;
    z-index: 100000001;
    align-items: center;
    justify-content: center;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
