@import '~@ebay/skin/tokens.css';
@import '~@ebay/skin/less.less';

.section {
  &__title, &__dataitems {
    margin-top: 5px;
    font-size: @font-size-12;
  }
  &__dataitems {
    color: var(--color-foreground-primary);
  }
}
