@import '~@ebay/skin/less.less';
@import '~@ebay/skin/global.css';
@import '~@ebay/skin/tokens.css';

@vas-ti-secondary-text-color: #5E5E5E;

.how-works {
  background-color: var(--color-background-secondary);
  border-radius: var(--border-radius-50);
  padding: 16px;

  &__title {
    margin-bottom: 16px;
  }
}
