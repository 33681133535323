@import '~@ebay/skin/tokens.css';
@import '~@ebay/skin/less.less';

.appointment {
  padding-right: 5px;
  position: relative;

  &__date {
    margin: 16px 0 5px 0;
    .section__title {
      font-size: @font-size-16;
    }

    .date-dropdown {
      margin-top: 10px;

      .floating-label {
        width: 100%;
        .date-dropdown__select {
          width: 100%;
          select[name="dropoff-date"] {
            width: 100%;
          }
        }
      }
    }

    &-calendar {
      margin-top: 10px;
      padding-top: 5px;
    }

    .vas-disclosure {
      margin-top: 15px;
      .typography-small();
    }
  }
}

@media (max-width: @_screen-size-MD) {
  .appointment {
    padding-right: 5px;
  }
}
