@import '~@ebay/skin/less.less';
@import '~@ebay/skin/tokens.css';

.radius {

  select {
    width: 178px;
  }

  &__error-message {
    margin: 0;
    padding: 0;
    color: var(--floating-label-invalid-color, var(--color-foreground-attention));

    .typography-small();
  }
}
