@import '~@ebay/skin/tokens.css';

.ctas {
  button, a {
    &.fake-btn--primary {
      &:hover {
        color: var(--color-background-primary);
      }
    }

    &:last-child {
      margin: 0;
    }
  }

  &__ok {
    text-align: center;
    background-color: var(--color-background-primary);
    color: var(--color-foreground-secondary);
    border-bottom: 1px solid var(--color-background-disabled);
    height: 0px;

    span {
      position: relative;
      top: -8px;
      background-color: var(--color-background-primary);
      padding: 5px;
    }
  }
  button:focus {
    outline: 1px solid var(--color-blue-500);
    outline-offset: 1px;
  }
}
