.vas-warranty-interstitial-nudge-d {
  margin: 1.5rem;
  font-size: 0.875rem;
  // font-family: 'Market Sans';

  a,
  a:focus,
  a:visited {
    color: #3665f3;
    text-decoration: underline;
  }

  .vas-interstitial-error {
    min-height: 6rem;

    &-notice {
      p {
        color: #111820;
        font-weight: 500 !important;
      }
    }
  }

  .vas-interstitial-header {
    display: flex;
    flex-shrink: 0;
    position: relative;
    align-items: center;
    // border-bottom: 1px solid #eee;
    margin-bottom: 1rem;

    &-text {
      flex: 1 1 auto;
      font-size: 1.25rem;
      color: #111820;
      // font-weight: 600;
      // text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
      // margin: 0 0.5rem;
      white-space: nowrap;
    }

    &-close-btn {
      height: 40px;
      width: 40px;
      border: none;
      background-color: #f7f7f7;
      border-radius: 50%;

      svg.icon.icon--close-16 {
        fill: #111820;
      }
    }
  }

  .vas-interstitial-items {
    margin-bottom: 1rem;

    .vas-interstitial-item {
      display: flex;
      justify-content: space-between;

      &-left {
        width: 85%;
      }

      &-label {
        line-height: 24px;
        color: #000000;
        font-size: 1rem;
        margin-bottom: 0.25rem;
      }
      &-secondaryLabel {
        line-height: 20px;
        color: #111820;
        margin-bottom: 0.25rem;
      }
      &-tertiaryLabel {
        line-height: 20px;
        color: #111820;
        margin-bottom: 0.5rem;
      }
      &-quaternaryLabel {
        line-height: 20px;
        color: #111820;
        margin-bottom: 0.5rem;
      }
      .link-label {
        margin-top: 0.75rem;
      }
    }
  }

  .vas-interstitial-cta {
    display: flex;
    justify-content: flex-end;
    flex-wrap: nowrap;
    position: absolute;
    bottom: 1.5rem;
    right: 1.5rem;

    &-btn {
      margin-left: 1rem;
      padding: 0px 16px 0px 16px;
    }
  }
}

// visibly hidden
.clipped {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}
