@import '~@ebay/skin/tokens.css';
.card-tile img,
.card-tile-vertical img {
  width: 64px;
  height: 64px;
}
.card-tile__card-left-icon,
.card-tile__card-left-image {
  border-radius: 10px;
  border: 1px solid transparent;
}
.card-tile__card-left-icon svg,
.card-tile__card-left-image svg {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.card-tile__card-left-icon {
  width: 64px;
  height: 64px;
  background-color: var(--color-background-tertiary);
  flex-grow: 0;
  flex-shrink: 0;
  box-sizing: border-box;
}
.card-tile h2 {
  margin: 0;
  padding: 0;
}
.card-tile__card-left-index {
  background-color: var(--color-background-secondary);
  border-radius: 50%;
  color: var(--color-neutral-900);
  text-align: center;
}
.card-tile-vertical h2 {
  margin: 16px 0;
  padding: 0;
}
.card-tile-vertical__card {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 16px 0;
  border-bottom: 1px solid var(--color-background-tertiary);
}
.card-tile-vertical__card:last-child {
  border-bottom: none;
}
.card-tile-vertical__card-right {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}
.card-tile-vertical__card-details > ul {
  padding: 0 15px 0 20px;
}
.card-tile__cards {
  padding: 16px 0 0;
  display: flex;
  justify-content: space-between;
}
.card-tile__card {
  box-sizing: border-box;
  width: 270px;
  height: 207px;
  padding-top: 20px;
}
.card-tile__card-left-index {
  height: 69px;
  line-height: 69px;
  min-width: 68px;
  width: 68px;
  font-size: 1.25rem;
  font-weight: 700;
}
.card-tile__card-title {
  padding: 24px 0 16px;
  font-size: var(--font-size-large-1);
  font-weight: var(--font-weight-bold);
  line-height: 28px;
}
.card-tile__card-details {
  font-size: var(--font-size-medium);
  line-height: 24px;
}
.card-tile-vertical__label {
  margin-bottom: 16px;
}
.card-tile-vertical__card-right {
  padding-left: 8px;
}
