@import '~@ebay/skin/global.css';
@import '~@ebay/skin/tokens.css';
.how-works {
  background-color: var(--color-background-secondary);
  border-radius: var(--border-radius-50);
  padding: 16px;
}
.how-works__title {
  margin-bottom: 16px;
}
.how-works {
  background-color: var(--color-background-secondary);
  border-radius: var(--border-radius-50);
  padding: 16px;
  height: 100%;
  margin: 0 0 50px 0;
}
.how-works ul,
.how-works li {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.how-works__header {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
.how-works__title {
  font-size: var(--font-size-medium);
  line-height: 24px;
  font-weight: var(--font-weight-bold);
}
.how-works__question,
.how-works__answer {
  font-size: var(--font-size-small);
  line-height: 16px;
}
.how-works .how-works__question {
  color: var(--color-foreground-primary);
  margin: 0 0 5px 0;
}
.how-works .how-works__answer {
  color: #5E5E5E;
  margin-bottom: 8px;
}
.how-works__scroll {
  max-height: 250px;
  overflow-y: scroll;
}
