@import './common.less';

.how-works {
  background-color: var(--color-background-secondary);
  border-radius: var(--border-radius-50);
  padding: 16px;
  height: 100%;
  margin: 0 0 50px 0;

  ul, li {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
  }

  &__title {

    .typography-medium-bold();
  }

  &__question,
  &__answer {
    .typography-small();
  }

  .how-works__question {
    color: var(--color-foreground-primary);
    margin: 0 0 5px 0;
  }

  .how-works__answer {
    color: @vas-ti-secondary-text-color;
    margin-bottom: 8px;
  }

  &__scroll {
    max-height: 250px;
    overflow-y: scroll;
  }
}
