.tires-personaldata__field-input {
  margin-bottom: 0.5rem;

  .tires-personaldata__select {
    width: 100%;

    select {
      width: 100%;
    }
  }

  input {
    text-indent: 16px;
    height: 40px;
  }

  p.tires-personaldata-field__error-message {
    color: var(--floating-label-invalid-color, var(--color-foreground-attention));
  }
}

.select-placeholder {
  color: var(--color-foreground-secondary);
}