.vas-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.vas-header {
  padding: 0px 16px;
}
.vas-header #vas-header-close-icon {
  height: 32px;
  width: 32px;
}
