@import '~@ebay/skin/tokens.css';
.introduction-header {
  padding: 0 16px;
  position: absolute;
  right: 0;
  top: 16px;
  z-index: 1;
}
.parts-introduction .introduction__top-content {
  height: 284px;
  background-color: #44CCD5;
}
.parts-introduction .introduction__top-content img {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.parts-introduction .introduction__content {
  height: calc(889px - 176px);
}
.parts-introduction .introduction__top-content {
  border-radius: 16px 16px 0 0;
}
.parts-introduction .introduction__top-content img {
  height: 284px;
}
.parts-introduction .introduction__bottom-content {
  padding: 24px 24px 16px;
  padding-bottom: 90px;
}
.parts-introduction .introduction__bottom-content .card-tile__label > h2 {
  font-size: var(--font-size-giant-3);
  font-weight: var(--font-weight-bold);
  line-height: 52px;
}
.parts-introduction .introduction__bottom-content .card-tile__card-right {
  margin-right: 24px;
}
.parts-introduction .introduction__footer {
  display: flex;
  justify-content: space-between;
  box-shadow: 0 -4px 4px #0000000f;
  border-radius: 0 0 10px 10px;
}
.parts-introduction .introduction__footer__sec-labels {
  padding: 20px;
}
.parts-introduction .introduction__footer__sec-labels .ux-textspans--PSEUDOLINK {
  color: var(--color-foreground-primary);
}
.parts-introduction .screen-loading {
  padding: 60px 32px 32px 32px;
  height: 750px;
}
.parts-introduction .screen-loading__content-right .skeleton__image {
  height: 600px;
}
