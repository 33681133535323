@import './common.less';

/** DESKTOP **/
.map {
  &__root {
    margin-bottom: 16px;
  }

  &__google-map {
    aspect-ratio: 95 / 67;
  }

  &__root,
  &__google-map {
    border: 1px solid transparent;
    border-radius: 8px;
    width: 100%;
    height: 100%;
  }

  &__container {
    border-radius: 50%;
    height: 24px;
    line-height: 25px;
    width: 24px;
  }

  &__num {
    display: block;
    flex: 1;
    overflow: hidden;
  }

  &__marker > input&__input {
    &:checked + label.map__container {

      .typography-medium-bold();

      width: 40px;
      height: 40px;
      line-height: 40px;
    }
  }
}

.arrow-tooltip {
  .map {
    &__num {
      display: none;
    }

    &__marker > input&__input-info-view {
      &:checked + label.map__container {

        .typography-medium-bold();

        width: 85px;
        height: 62px;
        line-height: 40px;
      }
    }
  }
}
