@import '~@ebay/skin/less.less';
@import '~@ebay/skin/tokens.css';

.parts-summary {
  &__image {
    width: 100%;
    background-color: var(--color-orange-300);

    img {
      position: relative;
      top: 50%;
      left: 50%;
      height: 100%;
      transform: translate(-50%, -50%);
    }
  }
  &__left {
    .card-tile-vertical__card {
      align-items: flex-start;
      &-details {
        font-size: 12px;
      }
      .card-tile-vertical__card {
        &-subtitles {
          font-size: 12px;
        }
      }
      &-details > ul {
        margin: 10px 0 0 0;
      }
    }
    .card-tile-vertical__card-right {
      padding-left: 8px;
      button {
        min-width: 40px;
      }
    }
    .card-tile__card-left-image {
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      background: #fff;
      width: 80px;
      height: 64px;
      position: relative;
      img {
        max-height: 100%;
        max-width: 100%;
        display: inline-block;
        object-fit: contain;
      }
      &::after {
        position: absolute;
        background: rgba(0, 0, 0, 0.10);
        height: 100%;
        width: 100%;
        content: '';
      }
    }
  }
  &__right {
    .parts-summary__infocard {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      border-radius: 5px;
      padding: 16px;
      background-color: var(--color-background-secondary);
      .parts-summary__footer-text {
        padding-left: 8px;
      }
      svg.icon--lightbulb-24{
        height: 24px;
        width: 24px;
      }
    }
    .parts-summary__feild-input {
      margin-bottom: 24px;
      input {
        height: 44px;
      }
      p {
        margin: 4px 0 0;
        color: var(--color-foreground-secondary);
        font-size: 12px;
      }
      p.parts-summary-feild__error-message {
        color: var(--floating-label-invalid-color, var(--color-foreground-attention));
      }
    }
  }
}
