@import '~@ebay/skin/tokens.css';
@import '~@ebay/skin/less.less';

.map {
  &__root {
    margin: 0;
    padding: 0;
  }

  &__google-map {
    width: 100%;
  }

  &__marker {
    position: relative;
    z-index: 1;
    padding: 0;
    border-radius: 8px;

    &&--selected {
      z-index: 2;
    }

    &:hover,
    &:focus {
      z-index: 3;
    }
  }

  &__marker > input&__input {
    display: block;
    height: 100%;
    opacity: 0;
    position: absolute;
    width: 100%;
    z-index: 1;

    &:checked + label.tires-map__container {
      background-color: var(--color-blue-500);
      color: var(--color-background-primary);
    }
  }

  &__container {
    background-color: var(--color-background-primary);
    border-radius: var(--border-radius-50);
    box-sizing: border-box;
    display: flex;
    filter: drop-shadow(0px 2px 7px rgb(0 0 0 / 15%)) drop-shadow(0px 5px 17px rgb(0 0 0 / 20%));
    flex-direction: column;
    text-align: center;
  }

  &__num {
    font-weight: @font-weight-bold;
  }

  &__installer-list,
  &__num,
  &__label,
  &__accessory-label-0,
  &__accessory-label-1,
  &__accessory-label-1,
  &__secondary-label-0,
  &__secondary-label-1,
  &__star-rating {
    display: none;
  }
}

.arrow-tooltip {
  .map {
    &__button-info-view,
    &__button-info-view-select {
      width: 106px;
      height: 60px;
      border-radius: 8px;
      box-sizing: border-box;
      border-color: transparent;
      background-color: var(--color-background-primary);
      filter: drop-shadow(0px 2px 7px rgb(0 0 0 / 15%)) drop-shadow(0px 5px 17px rgb(0 0 0 / 20%));

      &::after {
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-top: 8px solid var(--color-background-primary);
        content: '';
        left: 50%;
        position: absolute;
        top: 100%;
        transform: translateX(-50%);
      }

      svg.star-rating__icon {
        display: none;
        &:first-child {
          display: block;
        }
      }
    }

    &__button-info-view {
      color: var(--color-neutral-900);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    &__button-info-view-select {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: var(--color-blue-500);
      color: var(--color-background-primary);
      // skin overrides for color
      div.star-rating svg {
        --color-star-rating-full: var(--color-background-primary);
        --color-star-rating-full-stroke: var(--color-background-primary);
        --color-star-rating-half-stroke: var(--color-background-primary);
      }
      &::after {
        border-top: 8px solid var(--color-blue-500);
      }
    }

    &__secondary-label-0 {
      display: block;
      span {
        &:first-child {
          display: none;
        }
      }
    }

    &__secondary-label-1 {
      display: block;
      height: 18px;
      margin-top: 5px;
    }

    &__star-rating {
      display: flex;
      justify-content: center;
      margin-top: 6px;

      &-average {
        margin: 2px 0 0 5px;
      }
    }

    &__installer-card {
      padding: 0;
      color: inherit;
      background-color: transparent;
      border: 0;

      .installer-card-no-radio {
        width: 300px;
        &__name,
        &__address span {
          .typography-regular();
        }
      }
    }
  }
}

// google map style overrides

.gm-style-iw+div {
  display: none;
}
