@import '~@ebay/skin/tokens.css';
.parts-summary__image {
  width: 100%;
  background-color: var(--color-orange-300);
}
.parts-summary__image img {
  position: relative;
  top: 50%;
  left: 50%;
  height: 100%;
  transform: translate(-50%, -50%);
}
.parts-summary__left .card-tile-vertical__card {
  align-items: flex-start;
}
.parts-summary__left .card-tile-vertical__card-details {
  font-size: 12px;
}
.parts-summary__left .card-tile-vertical__card .card-tile-vertical__card-subtitles {
  font-size: 12px;
}
.parts-summary__left .card-tile-vertical__card-details > ul {
  margin: 10px 0 0 0;
}
.parts-summary__left .card-tile-vertical__card-right {
  padding-left: 8px;
}
.parts-summary__left .card-tile-vertical__card-right button {
  min-width: 40px;
}
.parts-summary__left .card-tile__card-left-image {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background: #fff;
  width: 80px;
  height: 64px;
  position: relative;
}
.parts-summary__left .card-tile__card-left-image img {
  max-height: 100%;
  max-width: 100%;
  display: inline-block;
  object-fit: contain;
}
.parts-summary__left .card-tile__card-left-image::after {
  position: absolute;
  background: rgba(0, 0, 0, 0.1);
  height: 100%;
  width: 100%;
  content: '';
}
.parts-summary__right .parts-summary__infocard {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 5px;
  padding: 16px;
  background-color: var(--color-background-secondary);
}
.parts-summary__right .parts-summary__infocard .parts-summary__footer-text {
  padding-left: 8px;
}
.parts-summary__right .parts-summary__infocard svg.icon--lightbulb-24 {
  height: 24px;
  width: 24px;
}
.parts-summary__right .parts-summary__feild-input {
  margin-bottom: 24px;
}
.parts-summary__right .parts-summary__feild-input input {
  height: 44px;
}
.parts-summary__right .parts-summary__feild-input p {
  margin: 4px 0 0;
  color: var(--color-foreground-secondary);
  font-size: 12px;
}
.parts-summary__right .parts-summary__feild-input p.parts-summary-feild__error-message {
  color: var(--floating-label-invalid-color, var(--color-foreground-attention));
}
.parts-summary {
  padding: 0 32px;
  border-radius: 8px;
}
.parts-summary__image {
  height: 192px;
}
.parts-summary__content {
  padding: 20px 0 0;
  display: flex;
  padding-bottom: 80px;
}
.parts-summary__left {
  width: 408px;
  padding-right: 10px;
}
.parts-summary__right {
  margin-left: 60px;
  padding-right: 10px;
  width: 355px;
}
.parts-summary__right .parts-summary__feild-input input {
  text-indent: 14px;
}
.parts-summary__right .parts-summary__title {
  margin-bottom: 24px;
}
.parts-summary__right .parts-summary__footer-text {
  font-size: 14px;
}
.parts-summary__right .parts-summary__footerTexts {
  background-color: var(--color-background-secondary);
  border-radius: 5px;
  padding: 8px 16px;
  margin: 20px 0 10px 0;
  font-size: var(--font-size-small);
  line-height: 16px;
}
.parts-summary__right .parts-summary__footerTexts .parts-summary__footer-text {
  font-size: var(--font-size-small);
  line-height: 16px;
}
.parts-summary__right .parts-summary__footerTexts .parts-summary__footer-text span.ux-textspans--PSEUDOLINK {
  color: var(--color-foreground-primary);
}
.parts-summary__footer {
  box-shadow: 0 -4px 4px #0000000f;
}
.parts-summary__footer.sticky_footer {
  border-radius: 0 0 10px 10px;
}
.parts-summary-loading .screen-loading {
  padding: 0 32px 32px;
  height: 750px;
}
.parts-summary-loading .screen-loading__content-top .skeleton__textbox {
  height: 192px;
}
.parts-summary-loading .screen-loading__content-main {
  padding-top: 32px;
}
.parts-summary-loading .screen-loading__content-right .skeleton__image {
  height: 150px;
  margin-bottom: 20px;
}
