@import './common.less';

.ctas {
  display: flex;
  flex-direction: row-reverse;
  padding: 0 16px 16px 0;

  button, a {
    margin: 0 0 0 16px;
  }
}
