@import './common.less';

.installer-list {
  height: 390px;
  overflow-y: scroll;
  padding-right: 16px;

  ul.toggle-button-group li {
    width: 90%;
  }
}

.no-radio {
  padding-right: 0;
}