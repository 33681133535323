@import '~@ebay/skin/tokens.css';
.installer-list {
  width: 100%;
}
.installer-list .filter-menu {
  width: 100%;
}
.installer-list .filter-menu .filter-menu__items {
  margin-top: 0px;
}
.installer-list .filter-menu__radio {
  padding: 0 8px;
}
.installer-list .filter-menu__item[role="menuitemradio"] {
  border-bottom: 1px solid var(--color-background-tertiary);
  padding: 16px 0;
}
.installer-list .filter-menu .filter-menu__items > div:first-child {
  border-top: 1px solid var(--color-background-tertiary);
}
.installer-list button.toggle-button {
  border: 0;
  border-bottom: 1px solid var(--color-background-tertiary);
  border-radius: 0;
  padding: 16px 0;
  justify-content: flex-start;
  width: 350px;
}
.installer-list button.toggle-button:focus {
  background-color: var(--color-state-primary-hover);
}
.installer-list {
  height: 390px;
  overflow-y: scroll;
  padding-right: 16px;
}
.installer-list ul.toggle-button-group li {
  width: 90%;
}
.no-radio {
  padding-right: 0;
}
