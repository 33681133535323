@import './common.less';

// default styles

.card-tile {
  &__cards {
    padding: 16px 0 0;
    display: flex;
    justify-content: space-between;
  }
  &__card {
    box-sizing: border-box;
    width: 270px;
    height: 207px;
    padding-top: 20px;

    &-left-index {
      height: 69px;
      line-height: 69px;
      min-width: 68px;
      width: 68px;
      font-size: @font-size-large-1;
      font-weight: @font-weight-bold;
    }

    &-title {
      padding: 24px 0 16px;

      .typography-large-1();
    }

    &-details {
      .typography-medium();
    }
  }
}

// vertical styles
.card-tile-vertical {
  &__label {
    margin-bottom: 16px;
  }

  &__card-right {
    padding-left: 8px;
  }
}
