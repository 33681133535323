@import '~@ebay/skin/tokens.css';
.map__root {
  margin: 0;
  padding: 0;
}
.map__google-map {
  width: 100%;
}
.map__marker {
  position: relative;
  z-index: 1;
  padding: 0;
  border-radius: 8px;
}
.map__marker.map__marker--selected {
  z-index: 2;
}
.map__marker:hover,
.map__marker:focus {
  z-index: 3;
}
.map__marker > input.map__input {
  display: block;
  height: 100%;
  opacity: 0;
  position: absolute;
  width: 100%;
  z-index: 1;
}
.map__marker > input.map__input:checked + label.tires-map__container {
  background-color: var(--color-blue-500);
  color: var(--color-background-primary);
}
.map__container {
  background-color: var(--color-background-primary);
  border-radius: var(--border-radius-50);
  box-sizing: border-box;
  display: flex;
  filter: drop-shadow(0px 2px 7px rgba(0, 0, 0, 0.15)) drop-shadow(0px 5px 17px rgba(0, 0, 0, 0.2));
  flex-direction: column;
  text-align: center;
}
.map__num {
  font-weight: 700;
}
.map__installer-list,
.map__num,
.map__label,
.map__accessory-label-0,
.map__accessory-label-1,
.map__accessory-label-1,
.map__secondary-label-0,
.map__secondary-label-1,
.map__star-rating {
  display: none;
}
.arrow-tooltip .map__button-info-view,
.arrow-tooltip .map__button-info-view-select {
  width: 106px;
  height: 60px;
  border-radius: 8px;
  box-sizing: border-box;
  border-color: transparent;
  background-color: var(--color-background-primary);
  filter: drop-shadow(0px 2px 7px rgba(0, 0, 0, 0.15)) drop-shadow(0px 5px 17px rgba(0, 0, 0, 0.2));
}
.arrow-tooltip .map__button-info-view::after,
.arrow-tooltip .map__button-info-view-select::after {
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid var(--color-background-primary);
  content: '';
  left: 50%;
  position: absolute;
  top: 100%;
  transform: translateX(-50%);
}
.arrow-tooltip .map__button-info-view svg.star-rating__icon,
.arrow-tooltip .map__button-info-view-select svg.star-rating__icon {
  display: none;
}
.arrow-tooltip .map__button-info-view svg.star-rating__icon:first-child,
.arrow-tooltip .map__button-info-view-select svg.star-rating__icon:first-child {
  display: block;
}
.arrow-tooltip .map__button-info-view {
  color: var(--color-neutral-900);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.arrow-tooltip .map__button-info-view-select {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--color-blue-500);
  color: var(--color-background-primary);
}
.arrow-tooltip .map__button-info-view-select div.star-rating svg {
  --color-star-rating-full: var(--color-background-primary);
  --color-star-rating-full-stroke: var(--color-background-primary);
  --color-star-rating-half-stroke: var(--color-background-primary);
}
.arrow-tooltip .map__button-info-view-select::after {
  border-top: 8px solid var(--color-blue-500);
}
.arrow-tooltip .map__secondary-label-0 {
  display: block;
}
.arrow-tooltip .map__secondary-label-0 span:first-child {
  display: none;
}
.arrow-tooltip .map__secondary-label-1 {
  display: block;
  height: 18px;
  margin-top: 5px;
}
.arrow-tooltip .map__star-rating {
  display: flex;
  justify-content: center;
  margin-top: 6px;
}
.arrow-tooltip .map__star-rating-average {
  margin: 2px 0 0 5px;
}
.arrow-tooltip .map__installer-card {
  padding: 0;
  color: inherit;
  background-color: transparent;
  border: 0;
}
.arrow-tooltip .map__installer-card .installer-card-no-radio {
  width: 300px;
}
.arrow-tooltip .map__installer-card .installer-card-no-radio__name,
.arrow-tooltip .map__installer-card .installer-card-no-radio__address span {
  font-size: var(--font-size-default);
  line-height: 20px;
}
.gm-style-iw + div {
  display: none;
}
/** DESKTOP **/
.map__root {
  margin-bottom: 16px;
}
.map__google-map {
  aspect-ratio: 95 / 67;
}
.map__root,
.map__google-map {
  border: 1px solid transparent;
  border-radius: 8px;
  width: 100%;
  height: 100%;
}
.map__container {
  border-radius: 50%;
  height: 24px;
  line-height: 25px;
  width: 24px;
}
.map__num {
  display: block;
  flex: 1;
  overflow: hidden;
}
.map__marker > input.map__input:checked + label.map__container {
  font-size: var(--font-size-medium);
  line-height: 24px;
  font-weight: var(--font-weight-bold);
  width: 40px;
  height: 40px;
  line-height: 40px;
}
.arrow-tooltip .map__num {
  display: none;
}
.arrow-tooltip .map__marker > input.arrow-tooltip .map__input-info-view:checked + label.map__container {
  font-size: var(--font-size-medium);
  line-height: 24px;
  font-weight: var(--font-weight-bold);
  width: 85px;
  height: 62px;
  line-height: 40px;
}
