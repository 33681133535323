@import '~@ebay/skin/less.less';
@import '~@ebay/skin/tokens.css';

.introduction-header {
  padding: 0 16px;
  position: absolute;
  right: 0;
  top: 16px;
  z-index: 1;
}
.parts-introduction {
  .introduction {
    &__top-content {
      height: 284px;
      // color code not supported in skin
      background-color: #44CCD5;

      img {
        position: relative;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}
