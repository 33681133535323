@import './common.less';

.parts-summary {
  padding: 0 32px;
  border-radius: 8px;

  &__image {
    height: 192px;
  }

  &__content {
    padding: 20px 0 0;
    display: flex;
    padding-bottom: 80px;
  }

  &__left {
    width: 408px;
    //height: 705px;
    //overflow-x: scroll;
    padding-right: 10px;
  }

  &__right {
    margin-left: 60px;
    padding-right: 10px;
    //height: 705px;
    //overflow-x: scroll;
    width: 355px;

    .parts-summary__feild-input {
      input {
        text-indent: 14px;
      }
    }

    .parts-summary__title {
      margin-bottom: 24px;
    }

    .parts-summary__footer-text {
      font-size: 14px;
    }

    .parts-summary__footerTexts {
      background-color: var(--color-background-secondary);
      border-radius: 5px;
      padding: 8px 16px;
      margin: 20px 0 10px 0;
      .typography-small();
      .parts-summary__footer-text {
        .typography-small();
        span.ux-textspans--PSEUDOLINK {
          color: var(--color-foreground-primary);
        }
      }
    }
  }

  &__footer {
    box-shadow: 0 -4px 4px #0000000f;
    &.sticky_footer {
      border-radius: 0 0 10px 10px;
    }
  }
  &-loading {
    .screen-loading {
      padding: 0 32px 32px;
      height: 750px;
      &__content-top {
        .skeleton__textbox {
          height: 192px;
        }
      }
      &__content-main {
        padding-top: 32px;
      }
      &__content-right {
        .skeleton__image {
          height: 150px;
          margin-bottom: 20px;
        }
      }
    }
  }
}
