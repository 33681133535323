@import '~@ebay/skin/tokens.css';

.sort {
  text-align: right;

  button.sort__icon {
    min-width: 100px;
    height: 30px;
    background-color: transparent;

    svg {
      padding-right: 5px;
    }
  }

  &__options {
    position: absolute;
    width: 310px;
    outline: 0;
    left: ~"calc(50% - 322px)";
    box-sizing: border-box;
    border: 2px solid transparent;
    text-align: left;
    box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.15), 0px 5px 17px rgba(0, 0, 0, 0.2);
    border-radius: 16px;
    padding: 16px 0;
    z-index: 5;
    background-color: var(--color-background-primary);
  }

  .sort__options {
    div.listbox__option {
      border: none;
    }
  }
}
