@import './common.less';

.vas-header {
  padding: 0px 16px;

  #vas-header-close-icon {
    height: 32px;
    width: 32px;
  }
}
