@import '~@ebay/skin/tokens.css';
.parts-installation .parts-header {
  padding: 0 32px;
  height: 64px;
}
.parts-installation .parts-header {
  padding: 0 24px;
}
.parts-installation .parts-header .header-title {
  width: 90%;
}
.parts-installation .sticky_footer {
  position: fixed;
  bottom: 0;
  text-align: right;
  justify-content: flex-end;
  right: 0;
  width: 100%;
  background-color: var(--color-background-primary);
  border-radius: 10px;
  padding-top: 16px;
  height: 60px;
}
