@import '~@ebay/skin/tokens.css';
@import '~@ebay/skin/less.less';

.vas-tire-installation__installation_v2 {

  .installer-card {

    .installer-card__name {
      line-height: 24px;
      font-size: @font-size-14;
    }

    .installer-card__dropoff__date-time {
      font-size: @font-size-12;
      line-height: 16px;
    }

    .installer-card__hours {
      margin: 3px 0 8px 0;
    }

    .installer-card__dropoff__date-time:last-child span {
      font-weight: 700;
    }

    .installer-card__accessory-label {
      font-size: @font-size-12;

      .ux-textspans--BOLD:first-child {
        font-weight: 700;
      }
    }

    .installer-card__index {
      display: none;
    }

    .ux-textspans--BOLD {
      font-weight: 400;
    }
  }
}

.installer-card {

  .installer-card {
    &__address span {
      display: block;
      .typography-small();
    }
  }

  &__dropoff__date-time span {
    display: block;
  }

  &__columns {
    display: flex;
    justify-content: space-between;
  }

  &__name {
    .typography-medium();
  }

  &__accessory-label {
    padding: 0 8px;
    text-align: right;
    white-space: nowrap;

    span:first-child span {
      color: var(--color-foreground-primary);
      font-weight: normal;
    }

    span {
      color: var(--color-foreground-secondary); // no var matches figma #767676
      display: block;
      font-size: @font-size-12;
      line-height: 20px;
    }
  }

  &__hours {
    margin: 8px 0;
    display: block;

    .typography-small-secondary();

    span {
      display: block;
    }

    &-title {
      display: none;
    }
  }

  &__dropoff {
    padding-top: 8px;
  }

  // with image and no radio buttons

  &-no-radio {
    display: flex;
    align-items: flex-start;
    text-align: left;
    position: relative;
    width: 345px;
    &__right {
      width: 255px;
    }

    &__left {

      // .imageTreatment(0);

      margin-right: 8px;
      margin-top: 8px;
      width: 80px;
      height: 80px;

      img {
        width: 80px;
        height: 80px;
        border-radius: 16px;
      }
    }

    &__name,
    &__address span {
      .typography-medium();
    }

    &__name,
    &__dropoff__date-time,
    .star-rating-average {
      display: block;
    }

    &__star-rating,
    &__accessory-label {
      display: flex;
      align-items: center;
    }

    &__star-rating-average {
      margin-left: 8px;
    }

    &__star-rating-average-pre-fix {
      margin-right: 8px;
    }

    &__star-rating-average-pre-fix,
    &__star-rating-average {
      .typography-regular-secondary();
    }

    &__accessory-label {
      padding: 0 8px;
      flex-wrap: wrap;
      white-space: pre-wrap;

      .typography-small-secondary();

      span {
        display: inline-block;
        padding-right: 4px;
        font-weight: normal;
      }
      margin-bottom: 10px;
    }

    &__hours {
      margin: 8px 0;
      display: block;

      .typography-small-secondary();

      span {
        display: block;
      }

      &-title {
        display: none;
      }
    }

    &__dropoff {
      padding-top: 8px;
    }

    &__dropoff__date-time {
      .typography-small();
    }

    &__feature {
      margin-left: 8px;
    }

    &__name,
    &__address,
    &__star-rating,
    &__accessory-label {
      padding: 4px 0 0;
    }
  }
}
