.find-area {
  display: flex;
  justify-content: space-between;
  gap: 16px;
  margin-bottom: 16px;

  &__installation-label {
    margin: 0;
    padding: 0;
  }
}
