@import '~@ebay/skin/tokens.css';
@import '~@ebay/skin/less.less';

.appointment {
  &__date {
    &-time {
      fieldset {
        .field {
          display: flex;
          .field__label {
            margin-left: 16px;
          }
        }
        .field.field-disable {
          .field__label .appointment__date-time-label, .field__label .appointment__date-time-seclabel span {
            color: var(--field-label-color-disabled, var(--color-foreground-disabled));
          }
        }
      }
      fieldset > div:first-of-type {
        margin-top: 0;
      }
      fieldset > div:last-child {
        margin-bottom: 0;
      }

      &-label {
        color: var(--color-foreground-primary);
      }
    }
  }
}
