@import '~@ebay/skin/less.less';
@import '~@ebay/skin/tokens.css';

.whats-included {
  width: 100%;
  border-bottom: 1px solid var(--color-background-tertiary);

  &__details {
    text-align: right;
    position: relative;
    bottom: 3px;

    p {
      margin: 0 0 12px;
      text-align: left;
    }

    summary.details__summary {
      padding: 0 0 10px;
    }

    ul {
      margin: 0;
      padding-left: 16px;
      .typography-small-secondary();
    }
  }
}
