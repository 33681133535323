@import '~@ebay/skin/tokens.css';
@import '~@ebay/skin/less.less';

.vas-spoke-d {
  margin: 1.5rem;
  font-size: @font-size-14;
  // font-family: 'Market Sans';

  a,
  a:focus,
  a:visited {
    color: #3665f3;
    text-decoration: underline;
  }

  .vas-spoke-header {
    display: flex;
    flex-shrink: 0;
    position: relative;
    align-items: center;
    // border-bottom: 1px solid #eee;
    margin-bottom: 2rem;

    &-text {
      flex: 1 1 auto;
      font-size: 1.25rem;
      color: #111820;
      // font-weight: 600;
      // text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
      // margin: 0 0.5rem;
      white-space: nowrap;
    }

    &-close-btn {
      height: 40px;
      width: 40px;
      border: none;
      background-color: #f7f7f7;
      border-radius: 50%;

      svg.icon.icon--close-24 {
        fill: #111820;
        display: inline;
      }
    }
  }

  .vas-spoke-header.vault-spoke-header {
    margin-bottom: auto;
  }

  .vas-spoke-items {
    .vas-spoke-item {
      display: flex;
      justify-content: space-between;

      &-left {
        width: 85%;
      }

      &-label {
        line-height: 24px;
        color: #000000;
        font-size: @font-size-14;
        margin-bottom: 0.25rem;
      }
      &-secondaryLabel {
        line-height: 20px;
        color: #111820;
        margin-bottom: 0.25rem;
      }
      &-tertiaryLabel {
        line-height: 20px;
        color: #111820;
        margin-bottom: 0.5rem;
      }
      &-quaternaryLabel {
        line-height: 20px;
        color: #111820;
        margin-bottom: 0.5rem;
      }
      .link-label {
        margin-top: 0.75rem;
      }
    }
  }

  .vas-spoke-cta {
    display: flex;
    justify-content: flex-end;
    flex-wrap: nowrap;

    &-btn {
      margin-left: 1rem;

      &-spinner {
        height: 16px;
        width: 16px;
      }
    }
  }

  .vas-spoke-body {
    border-top: 1px solid var(--color-background-tertiary);
    margin-top: @spacing-200;
    margin-bottom: @spacing-200;
  }

  .vas-spoke-body-main {
    margin-bottom: @spacing-200;
  }

  .spoke-body-primary {
    display: flex;
  }

  .spoke-body-label {
    margin-bottom: @spacing-200;
    margin-top: @spacing-200;
  }

  .vas-spoke-body-primary-left {
    flex: 1 1 auto;
  }

  .vas-spoke-footer {
    color: var(--color-foreground-secondary);
    font-size: @font-size-12;
  }

  .vas-spoke-tertiary-title {
    margin-top: @spacing-200;
  }

  .vas-header-text {
    margin-left: @spacing-50;
  }

  .vas-spoke-title {
    margin-bottom: @spacing-200;
    display: flex;
    align-items: center;
  }

  .icon-vault {
    display: inline-block;
    margin: 0;
    svg {
      height: 18px;
      width: 18px;
    }
  }

  .vault-divider {
    border-top: 1px solid var(--color-background-tertiary);
    border-bottom: 1px solid var(--color-background-tertiary);
    margin-bottom: @spacing-200;
    margin-top: @spacing-200;
  }

  .high-asp {
    .section-notice {
      display: block;
      .section-notice__header {
        height: auto;
      }
    }
  }
}
