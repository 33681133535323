@import '~@ebay/skin/tokens.css';
.whats-included {
  width: 100%;
  border-bottom: 1px solid var(--color-background-tertiary);
}
.whats-included__details {
  text-align: right;
  position: relative;
  bottom: 3px;
}
.whats-included__details p {
  margin: 0 0 12px;
  text-align: left;
}
.whats-included__details summary.details__summary {
  padding: 0 0 10px;
}
.whats-included__details ul {
  margin: 0;
  padding-left: 16px;
  font-size: var(--font-size-small);
  line-height: 16px;
  color: var(--color-foreground-secondary);
}
.whats-included {
  margin-bottom: 16px;
}
.whats-included__title {
  position: relative;
  top: 18px;
}
.whats-included__title .ux-textspans--BOLD {
  font-size: var(--font-size-medium);
  line-height: 24px;
  font-weight: var(--font-weight-bold);
}
.whats-included__title .whats-included__sub-heading {
  font-size: var(--font-size-small);
  line-height: 16px;
  color: var(--color-foreground-secondary);
  padding: 5px 0;
}
.whats-included__title span {
  display: block;
}
.whats-included__details {
  text-align: right;
  position: relative;
  bottom: 3px;
}
.vas-tire-installation__installation_v2 .whats-included {
  border-bottom: 0;
  padding-bottom: 15px;
  font-size: var(--font-size-small);
  line-height: 16px;
}
.vas-tire-installation__installation_v2 .whats-included .whats-included__details {
  top: 30px;
  position: relative;
  text-align: left;
}
.vas-tire-installation__installation_v2 .whats-included .whats-included__details p {
  padding-left: 10px;
}
.vas-tire-installation__installation_v2 .whats-included .whats-included__details summary.details__summary {
  font-size: var(--font-size-default);
  line-height: 20px;
  display: flex;
  justify-content: space-between;
  color: var(--color-foreground-primary);
}
