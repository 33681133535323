@import '~@ebay/skin/less.less';
@import '~@ebay/skin/tokens.css';

// common styles
.card-tile,
.card-tile-vertical {
  img {
    width: 64px;
    height: 64px;
  }
}

.card-tile__card-left-icon,
.card-tile__card-left-image {
  border-radius: 10px;
  border: 1px solid transparent;
  svg {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.card-tile__card-left-icon {
  width: 64px;
  height: 64px;
  background-color: var(--color-background-tertiary);
  flex-grow: 0;
  flex-shrink: 0;
  box-sizing: border-box;
}

// default styles
.card-tile {
  h2 {
    margin: 0;
    padding: 0;
  }

  &__card-left-index {
    background-color: var(--color-background-secondary);
    border-radius: 50%;
    color: var(--color-neutral-900);
    text-align: center;
  }
}

// vertical styles
.card-tile-vertical {
  h2 {
    margin: 16px 0;
    padding: 0;
  }

  &__card {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 16px 0;
    border-bottom: 1px solid var(--color-background-tertiary);

    &:last-child {
      border-bottom: none;
    }

    &-right {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }
  }

  &__card-details > ul {
    padding: 0 15px 0 20px;
  }
}