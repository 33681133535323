@keyframes animationname {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}
.screen-loading {
  padding: 32px 0;
}
.screen-loading__content-top {
  width: 100%;
}
.screen-loading__content-main {
  display: flex;
  justify-content: space-between;
  padding-top: 32px;
}
.screen-loading__content-left {
  width: 50%;
  height: 100%;
  justify-content: flex-start;
}
.screen-loading__content-left .skeleton {
  padding-bottom: 16px;
}
.screen-loading__content-right {
  width: 45%;
  height: 100%;
  justify-content: flex-end;
}
.screen-loading__content-footer {
  margin-top: 10px;
}
